import HttpClient from "../lib/http-client";

export default class MenuBuilderService extends HttpClient {
  getClassroomCategories(classroomId) {
    const apiUrl = `/classroom/${classroomId}/menu`;
    return this.http.get(apiUrl);
  }

  createCustomCategory(classroomId, category) {
    const apiUrl = `/classroom/${classroomId}/categories`;
    const body = category;
    return this.http.post(apiUrl, body);
  }

  addCustomCategories(classroomId, categories) {
    const apiUrl = `/classroom/${classroomId}/add_categories`;
    const body = { categories };
    return this.http.post(apiUrl, body);
  }

  updateCustomCategory(categoryId, classroomId, category) {
    const apiUrl = `/classroom/${classroomId}/categories/${categoryId}`;
    const body = category;
    return this.http.put(apiUrl, body);
  }

  deleteCustomCategory(classroomId, categoryId) {
    const apiUrl = `/classroom/${classroomId}/categories/${categoryId}`;
    return this.http.delete(apiUrl);
  }

  updateCategories(classroomId, orgCode, categories) {
    const apiUrl = `/classroom/${classroomId}/categories`;
    return this.http.put(apiUrl, { orgCode, categories });
  }

  resetMenuToDefault(classroomId) {
    const apiUrl = `/classroom/${classroomId}/menu/reset`;
    return this.http.put(apiUrl);
  }

  addModulesToCustomCategory(classroomId, categoryId, modules) {
    const apiUrl = `/classroom/${classroomId}/categories/${categoryId}/modules`;
    return this.http.post(apiUrl, modules);
  }

  updateModulesInCustomCategory(classroomId, categoryId, modules) {
    const apiUrl = `/classroom/${classroomId}/categories/${categoryId}/modules`;
    return this.http.put(apiUrl, modules);
  }

  updateModuleSimulations({ classroomId, categoryId, moduleId, simulations }) {
    const apiUrl = `/classroom/${classroomId}/categories/${categoryId}/module/${moduleId}/simulations`;
    return this.http.put(apiUrl, { simulations });
  }

  getActivityLogs(classroomId) {
    const apiUrl = `/classroom/${classroomId}/menu/activity`;
    return this.http.get(apiUrl);
  }

  getMenuDetails({ classroomId }) {
    const apiUrl = `/classroom/${classroomId}/menu/details`;
    return this.http.get(apiUrl);
  }

  resetCeMenuToDefault(classroomId) {
    return this.http.put(`/classroom/${classroomId}/ce/menu/reset`, {
      simulation_codes: [],
      old_simulation_codes: [],
    });
  }
}
