import { MasteryStatus } from "../../../../models/mastery";
import { StatusFilter } from "../views/classroom.progress.ts.tab";

export const getSimsNames = (modules) => {
  const simsNames = [];
  for (const m of modules) {
    simsNames.push(...m.simulations.map((s) => s.displayName));
  }
  return simsNames;
};

export const mergeMasteryInfo = (trainees, modules, masteryInfo) => {
  return modules.map((module) => ({
    ...module,
    simulations: module.simulations.map((sim) => {
      const moduleMastery = masteryInfo?.get(module.moduleId);
      const simMastery = moduleMastery?.get(sim.learningObjectiveId);
      const traineesMastery = simMastery
        ? trainees.map((t) => ({ ...t, ...simMastery.get(t.userId) }))
        : [...trainees];
      return { ...sim, mastery: traineesMastery };
    }),
  }));
};

export const mergeMasteryInfoGradebook = (trainees, modules, masteryInfo) => {
  const modulesMap = new Map();
  modules?.map((module) => {
    const moduleMastery = masteryInfo?.get(module.moduleId);
    const result = trainees.map((t) => {
      const traineeObject = { ...t };
      module.simulations.map((sim) => {
        const simMastery = moduleMastery?.get(sim.learningObjectiveId);
        const traineeMastery = simMastery?.get(t.userId);
        traineeObject[sim.learningObjectiveId] = { ...traineeMastery };
      });
      return traineeObject;
    });
    modulesMap.set(module.moduleId, { ...module, users: result });
  });
  return modulesMap;
};

export const mergeCeMasteryInfo = (explorers, simulations, masteryInfo) => {
  return simulations.map((sim, index) => ({
    ...sim,
    index: index + 1,
    explorers: explorers.map((e) => {
      const simMastery = masteryInfo?.get(sim.learningExperienceId);
      const explorerMastery = simMastery?.get(e.userId) ?? {};
      return { ...e, ...explorerMastery };
    }),
  }));
};

export const mergeCeMasteryInfoWithData = (
  explorers,
  simulations,
  masteryInfo
) => {
  const data = simulations?.map((sim, index) => {
    const explorersWithMasteryData = explorers
      .map((explorer) => {
        const simMastery =
          masteryInfo?.get(sim.learningExperienceId) &&
          masteryInfo?.get(sim.learningExperienceId);
        const explorerMastery = simMastery?.get(explorer.userId);
        return explorerMastery && { ...explorer, ...explorerMastery };
      })
      .filter((e) => e);

    return {
      ...sim,
      index: index + 1,
      explorers: explorersWithMasteryData,
    };
  });

  const exploredSims = [];
  const unexploredSims = [];
  for (const sim of data) {
    masteryInfo.get(sim?.learningExperienceId)
      ? exploredSims.push(sim)
      : unexploredSims.push(sim);
  }

  return { exploredSims: exploredSims, unexploredSims: unexploredSims };
};

export const searchSimsByName = (modules, term) => {
  const filteredModules = modules
    .map((m) => {
      const filteredSims = m.simulations.filter((sim) =>
        sim.displayName.toLowerCase()?.includes(term?.toLowerCase())
      );
      return filteredSims?.length > 0 && { ...m, simulations: filteredSims };
    })
    .filter((m) => m.simulations);

  return filteredModules;
};

export const filterModules = (modules, { status, objectId, moduleId }) => {
  const filteredModules = modules
    ?.filter((m) => {
      if (moduleId) {
        return moduleId === m.moduleId ? m : undefined;
      } else {
        return m;
      }
    })
    .map((module) => {
      const filteredSims = getSimsFiltered(
        module.simulations,
        status,
        objectId
      );
      return (
        filteredSims?.length > 0 && {
          ...module,
          simulations: filteredSims,
        }
      );
    })
    .filter((m) => m.simulations);

  return filteredModules;
};

const getSimsFiltered = (simulations, status, objectId) => {
  return simulations
    .map((sim) => {
      const filteredMastery = filterUsersMasterybyStatus(sim.mastery, status);
      const foundSim = filteredMastery?.length > 0 && {
        ...sim,
        mastery: filteredMastery,
      };

      if (objectId) {
        return foundSim.learningObjectiveId === objectId ? foundSim : undefined;
      } else {
        return foundSim;
      }
    })
    .filter((sim) => sim);
};

const filterUsersMasterybyStatus = (mastery, status) => {
  return mastery.filter((m) => {
    if (status === StatusFilter.All) {
      return m;
    } else if (status === StatusFilter.NotStarted) {
      return !m.status || m.status === status;
    } else if (status === StatusFilter.Started) {
      return m.status && m.status !== MasteryStatus.NotStarted;
    } else {
      return m.status === status;
    }
  });
};

export const mergeTrekUserMasteryInfo = (
  users,
  careersExplored,
  masteryInfo
) => {
  const usersMap = new Map(users.map((e) => [e.userId, e]));
  const careersMap = new Map();

  for (const [userId, data] of Object.entries(careersExplored)) {
    const { exploredCareers, selectedCareer, favoritedCareers } = data;

    if (!exploredCareers) {
      continue;
    }

    for (const career of exploredCareers) {
      const careerData = careersMap.get(career.id) ?? {
        id: career.id,
        title: career.title,
        description: career.description,
        onetCode: career.onetCode,
        transfrId: career.transfrId,
        users: [],
        totalSelected: 0,
        totalViews: 0,
      };
      const isFavorited = !!favoritedCareers?.some((fc) => fc.id === career.id);
      const isSelected = selectedCareer?.id === career.id;
      const isExploredVR = career.occupationCodes
        ? getUserExploredVRValue(userId, career.occupationCodes, masteryInfo)
        : undefined;
      const user = {
        ...usersMap.get(userId),
        isFavorited,
        isSelected,
        goals: isSelected ? selectedCareer.goals : undefined,
        isExploredVR,
        viewedAt: career.viewedAt,
      };
      careerData.totalViews += 1;
      careerData.totalSelected += isSelected ? 1 : 0;
      careerData.users.push(user);
      careersMap.set(career.id, careerData);
    }
  }

  return [...careersMap.values()];
};

const getUserExploredVRValue = (userId, occupationCodes, masteryInfo) => {
  for (const code of occupationCodes) {
    const simMastery = masteryInfo?.get(code);
    const userSimMastery = simMastery?.get(userId);

    // At least one sim completed to be true
    if (userSimMastery && userSimMastery.completed) {
      return true;
    }
  }

  return false;
};
