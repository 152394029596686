import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import clsx from "clsx";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";
import "./integration-select.scss";

const IntegrationSelect = ({ providers, onSelectProvider, icon }) => {
  const icons = useStoreState((state) => state.providerIcon.icons);
  const loadIcons = useStoreActions(
    (actions) => actions.providerIcon.loadIcons
  );
  const [providersWithIcons, setProvidersWithIcons] = useState([]);

  useEffect(() => {
    const providerIds = providers.map((provider) => provider.id);
    loadIcons(providerIds);

    setProvidersWithIcons(
      providers.map((provider) => ({
        ...provider,
        icon: icons[provider.id],
      }))
    );
  }, [providers, icons]);

  return (
    <Dropdown
      className={clsx("integration-dropdown", !!icon && "icon-only")}
      selected={{}}
      onOptionSelected={onSelectProvider}
    >
      <DropdownButton
        iconOnly={!!icon}
        icon={icon}
        className={"integration-dropdown-button primary"}
      >
        {!icon && "Add a new integration"}
      </DropdownButton>
      <DropdownItems>
        {providersWithIcons?.map((provider) => (
          <DropdownItem
            key={provider.id}
            value={provider}
            className={"integration-dropdown-item"}
          >
            <img
              className="provider-image"
              src={provider.icon}
              alt={provider.name}
            />
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
};

export default IntegrationSelect;
