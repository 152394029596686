import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import { Badge, HyphenIcon } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { ActivityLogModal, ResetMenuModal } from "../modals/index.js";

import "./ce-menu-builder.header.scss";
import { Product } from "../../../../../../models/product.js";
import { EditCareersModal } from "../modals/edit-careers/edit-careers.modal.jsx";

export const CeMenuBuilderHeader = ({
  simulations,
  mergedSimulations,
  className,
  classroom,
  onResetMenu,
  onEditSimulations,
  enableEdit,
}) => {
  const [actionsDisabled, setActionsDisabled] = useState();
  const [openEditCareersModal, setOpenEditCareersModal] = useState();
  const [openCeResetMenuModal, setOpenCeResetMenuModal] = useState();
  const [openActivityLogModal, setOpenActivityLogModal] = useState();

  const simulationsCount = useMemo(() => simulations?.length, [simulations]);

  const onEditSimulationsHandler = (simulations, oldSimulations) => {
    return onEditSimulations(simulations, oldSimulations).finally(() =>
      setOpenEditCareersModal()
    );
  };

  useEffect(() => {
    setActionsDisabled(!mergedSimulations);
  }, [mergedSimulations]);

  const onActivityLogHandler = () => {
    setOpenActivityLogModal(true);
  };

  const onResetMenuHandler = () => {
    return onResetMenu().then(() => setOpenCeResetMenuModal());
  };

  return (
    <>
      <div className={clsx("menu-builder-header", className)}>
        <div className="left-section">
          <h2>Careers</h2>
          <Badge value={simulationsCount ?? <HyphenIcon />} />
          {enableEdit && (
            <>
              <Button
                icon={["fa-regular", "pen-to-square"]}
                onClick={() => setOpenEditCareersModal(true)}
                disabled={actionsDisabled}
                tooltip="Edit Careers"
              />
            </>
          )}
        </div>
        <div className="right-section">
          {enableEdit && (
            <Button
              icon={["fa-regular", "clock-rotate-left"]}
              onClick={() => setOpenCeResetMenuModal(true)}
              disabled={actionsDisabled}
            >
              Reset to Default
            </Button>
          )}
          <Button
            icon={["fa-regular", "list-timeline"]}
            onClick={() => onActivityLogHandler()}
            disabled={actionsDisabled}
          >
            Activity Log
          </Button>
        </div>
      </div>
      {createPortal(
        <ActivityLogModal
          open={openActivityLogModal}
          onClose={() => setOpenActivityLogModal()}
          classroom={classroom}
          async
        ></ActivityLogModal>,
        document.body
      )}
      {createPortal(
        <EditCareersModal
          open={openEditCareersModal}
          onClose={() => setOpenEditCareersModal()}
          simulations={mergedSimulations}
          onApplyChanges={onEditSimulationsHandler}
          async
        />,
        document.body
      )}
      {createPortal(
        <ResetMenuModal
          open={openCeResetMenuModal}
          onClose={() => setOpenCeResetMenuModal()}
          onConfirmReset={onResetMenuHandler}
          product={Product.CE}
          async
        />,
        document.body
      )}
    </>
  );
};
