/**
 * Base class for all Snowplow entities that enriches events with additional context.
 * Entities provide structured contextual information that can be attached to Snowplow events,
 * following predefined schemas for data consistency and validation.
 *
 * @abstract
 */
class Entity {
  /**
   * Creates a new entity with the specified Snowplow schema.
   * @param {string} schema - The Iglu schema URI that defines the entity structure
   */
  constructor(schema) {
    this.schema = schema;
    this.data = {};
  }

  /**
   * Formats the entity as a Snowplow context object (schema + data).
   * Required for adding global context to a tracker as instantiating an
   * object instance does not work.
   * @returns {Object} A context object
   */
  toContext() {
    return {
      schema: this.schema,
      data: this.data,
    };
  }
}

/**
 * Represents a Snowplow entity that identifies and tracks organization-level data.
 * This entity is used to associate events and other entities with a specific organization.
 */
export class Organization extends Entity {
  /**
   * Creates a new organization entity with the specified organization code.
   * @param {string} organizationCode - The code identifying the organization.
   */
  constructor(organizationCode) {
    super("iglu:com.transfrvr/organization/jsonschema/2-0-0");
    this.data.organization_code = organizationCode;
  }
}

/**
 * Represents a Snowplow entity that identifies an impersonator of a user.
 */
export class Impersonator extends Entity {
  /**
   * Creates a new impersonator entitiy with the userId
   * @param {string} userId - The id identifying the user
   */
  constructor(userId) {
    super("iglu:com.transfrvr/impersonator/jsonschema/1-0-0");
    this.data.user_id = userId;
  }
}
