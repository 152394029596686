/* eslint-disable unicorn/no-null */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useStoreState } from "easy-peasy";

import {
  useLiveClassroomData,
  useUpdateImageStreamEnabled,
  useLiveClassroomImages,
} from "@transfr-inc/dashboard-sockets";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";

import {
  MissionControlContainer,
  MissionControlFullScreenModal,
} from "../components/mission-control";

import { checkObjectIsPopulated } from "../utils/mission-control.utils";

export const ClassroomMissionControlTab = ({ classroom, users = [] }) => {
  const [fullScreen, setFullScreen] = useState();
  const socketUrl = process.env.SOCKET_BRIDGE_URL;
  const { currentUser } = useStoreState((store) => store.account);
  const { missionControlEnabled, imageStreamingEnabled } = useStoreState(
    (state) => state.app
  );
  const { token, missionControl } = currentUser;
  const showMissionControl = missionControl && missionControlEnabled;
  const [initSorting, setInitSorting] = useState();
  const [missionControlDataArray, setMissionControlDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const spotlightTile = useRef();
  const setSpotlightTile = (tile) => {
    spotlightTile.current = tile;
    if (tile) {
      setFullScreen(true);
    }
  };

  const timeoutRef = useRef(null);
  const sortRef = useRef(null);

  const classroomId = classroom?.classroomId;
  const clientCode = classroom?.clientCode;
  const isStreamingEnabled = classroom?.isStreamingEnabled;

  const [showLiveFeed, setShowLiveFeed] = useState(isStreamingEnabled ?? true);

  const { insightsService, classroomService } = container;

  const missionControlClassroom = showMissionControl
    ? useLiveClassroomData(socketUrl, token, clientCode, classroomId)
    : {};

  const imageData = imageStreamingEnabled
    ? useLiveClassroomImages(socketUrl, token, clientCode, classroomId)
    : {};

  // Gets mastery for star ratings
  const { response: ceResultsResponse } = useApiRequest(() =>
    insightsService.getClassroomCeResults(classroomId)
  );

  // Convert object map into usable array
  const classroomArray = useMemo(() => {
    const isDataPopulated = checkObjectIsPopulated(missionControlClassroom);
    let data = [];
    if (isDataPopulated) {
      data = Object.entries(missionControlClassroom).map(([userId, info]) => {
        const userInfo = users.find((user) => user.userId === userId) || {};
        const hasUserInfo = checkObjectIsPopulated(userInfo);
        if (hasUserInfo) {
          const matchingLearningExperienceResults =
            ceResultsResponse instanceof Map
              ? ceResultsResponse.get(info.learningExperienceId)
              : {};
          const studentResults =
            matchingLearningExperienceResults instanceof Map
              ? matchingLearningExperienceResults.get(userId)
              : {};

          return {
            userInfo,
            userId,
            studentResults,
            ...info,
          };
        }
        return;
      });
    }

    return data.filter((e) => e);
  }, [missionControlClassroom, ceResultsResponse]);

  const { isConnected, sendControlMessage } = useUpdateImageStreamEnabled(
    socketUrl,
    token,
    clientCode,
    classroomId
  );

  const updateLiveFeed = (newShowLiveFeed) => {
    classroomService.updateClassroomIsStreamingEnabled(
      classroomId,
      newShowLiveFeed
    );
  };

  const onToggleLiveFeed = () => {
    setLoading(true);
    setShowLiveFeed((prevShowLiveFeed) => {
      const newShowLiveFeed = !prevShowLiveFeed;
      sendControlMessage({ isStreamingEnabled: newShowLiveFeed });
      updateLiveFeed(newShowLiveFeed);
      return newShowLiveFeed;
    });
    setLoading();
  };

  useEffect(() => {
    return () => {
      setInitSorting();
      setMissionControlDataArray([]);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (sortRef.current) sortRef.current = null;
    };
  }, []);

  useEffect(() => {
    // Update the sorting ref whenever classroomArray changes
    sortRef.current = classroomArray;
  }, [classroomArray]);

  const sortData = () => {
    const sorted = sortRef.current.sort((a, b) => {
      const lastNameA = a?.userInfo?.lastName || "";
      const lastNameB = b?.userInfo?.lastName || "";
      return lastNameA.localeCompare(lastNameB);
    });

    setMissionControlDataArray(sorted);
    setLoading(false);
  };

  useEffect(() => {
    if (classroomArray?.length) {
      if (!initSorting) {
        setLoading(true);
        setInitSorting(true);

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          sortData();
        }, 5000);
      } else if (missionControlDataArray.length > 0) {
        setMissionControlDataArray((prevData) => {
          // Update existing items
          const updatedData = prevData.map((existingItem) => {
            const matchingItem = classroomArray.find(
              (item) => item.userId === existingItem.userId
            );
            return matchingItem
              ? { ...existingItem, ...matchingItem }
              : existingItem;
          });

          // Add new items
          const newItems = classroomArray.filter(
            (item) =>
              !prevData.some(
                (existingItem) => existingItem.userId === item.userId
              )
          );

          // Combine updated and new items
          return [...updatedData, ...newItems];
        });

        setLoading(false); // Ensure loading is set correctly
      }
    }
  }, [
    initSorting,
    JSON.stringify(classroomArray),
    JSON.stringify(missionControlDataArray),
    JSON.stringify(imageData),
  ]);

  const showZero = missionControlDataArray?.length === 0;

  const handleCloseFullScreen = () => {
    setFullScreen();
    setSpotlightTile();
  };

  const MissionControlWrapper = () => (
    <MissionControlContainer
      classroom={classroom}
      users={users}
      fullScreen={fullScreen}
      setFullScreen={setFullScreen}
      loading={loading}
      showZero={showZero}
      missionControlDataArray={missionControlDataArray}
      isConnected={isConnected}
      showLiveFeed={showLiveFeed}
      onToggleLiveFeed={onToggleLiveFeed}
      setSpotlightTile={setSpotlightTile}
      spotlightTile={spotlightTile.current}
      imageData={imageData}
      handleCloseFullScreen={handleCloseFullScreen}
    />
  );

  return (
    <>
      {fullScreen ? (
        <MissionControlFullScreenModal
          open={fullScreen}
          onClose={handleCloseFullScreen}
        >
          {MissionControlWrapper()}
        </MissionControlFullScreenModal>
      ) : (
        <>{MissionControlWrapper()}</>
      )}
    </>
  );
};
