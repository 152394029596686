export const EVENT_TYPES = {
  PAGE_VIEW: "pv",
  LINK_CLICK: "link_click",
  BUTTON_CLICK: "button_click",
};

export const LOGOUT_TYPES = {
  SSO: "sso",
  CORE_PLATFORM: "core-platform",
  IMPERSONATION: "impersonation",
};
