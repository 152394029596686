import { Impersonator } from "../models/tracking/entities";
import { DeauthenticateUser } from "../models/tracking/events";
import container from "../container";

const { trackingService } = container;

export const trackLogout = (logoutType, impersonatorId) => {
  if (!logoutType) return;

  const impersonator = [];
  if (impersonatorId) {
    impersonator.push(new Impersonator(impersonatorId));
  }

  trackingService.trackEvent(new DeauthenticateUser(logoutType), impersonator);
};
