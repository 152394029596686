import React, { useEffect, useState } from "react";
import container from "../../../../../../container";
import { CeMenuBuilderHeader } from "./ce-menu-builder.header";
import { useApiRequest } from "../../../../../../lib/http-client";
import { CeMenuBuilderSimulations } from "./ce-menu-builder-simulations";

import "./ce-menu-builder.scss";
import {
  GenericError,
  UpdateCareersSuccess,
  ResetToDefaultSuccess,
} from "../utils/notification.constants";
import {
  Loader,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";

export const ClassroomCeMenuBuilder = ({ classroom, enableEdit }) => {
  const { simulationsService, menuBuilderService } = container;

  const [notifications, setNotifications] = useState([]);
  const [mergedSimulations, setMergedSimulations] = useState([]);

  const {
    loading,
    response: classroomCeSimulations,
    sendRequest: getClassroomCeSimulations,
  } = useApiRequest(() =>
    simulationsService
      .getClassroomSimulations(classroom?.classroomId)
      .catch((error) => console.error(error))
  );

  const { response: orgCeSimulations, sendRequest: getOrgCeSimulations } =
    useApiRequest(() =>
      simulationsService
        .getOrganizationCeSimulations(classroom?.clientCode)
        .catch((error) => console.error(error))
    );

  const showSuccessNotification = (message) => {
    showNotification(NotificationType.success, message, "circle-check");
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  const onResetMenu = async () => {
    const { classroomId } = classroom;
    try {
      await menuBuilderService.resetCeMenuToDefault(classroomId);
      getClassroomCeSimulations();
      showSuccessNotification(ResetToDefaultSuccess);
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onEditSimulations = async (updatedSimulations, oldSimulations) => {
    const { classroomId } = classroom;

    const updatedSimulationsList = updatedSimulations
      .filter((sim) => sim.isVisible)
      .sort(
        (a, b) =>
          (a.orderNumber ?? Number.POSITIVE_INFINITY) -
          (b.orderNumber ?? Number.POSITIVE_INFINITY)
      )
      .map((sim) => sim.id);

    const oldSimulationsList = oldSimulations.map((sim) => sim.id);

    try {
      await simulationsService.updateClassroomSimulations(
        classroomId,
        updatedSimulationsList,
        oldSimulationsList
      );
      getClassroomCeSimulations();
      showSuccessNotification(UpdateCareersSuccess());
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  useEffect(() => {
    if (classroomCeSimulations && orgCeSimulations) {
      const simulationMap = new Map();

      for (const sim of classroomCeSimulations) {
        simulationMap.set(sim.id, { ...sim, isVisible: true });
      }

      for (const sim of orgCeSimulations) {
        if (!simulationMap.has(sim.id)) {
          simulationMap.set(sim.id, { ...sim });
        }
      }

      const mergedArray = [...simulationMap.values()];
      mergedArray.sort(
        (a, b) =>
          (a.orderNumber ?? Number.POSITIVE_INFINITY) -
          (b.orderNumber ?? Number.POSITIVE_INFINITY)
      );

      setMergedSimulations(mergedArray);
    }
  }, [classroomCeSimulations, orgCeSimulations]);

  return (
    <div className="ce-menu-builder-container">
      {loading && <Loader overlay fixed />}
      <CeMenuBuilderHeader
        simulations={classroomCeSimulations}
        mergedSimulations={mergedSimulations}
        classroom={classroom}
        enableEdit={enableEdit}
        onResetMenu={onResetMenu}
        onEditSimulations={onEditSimulations}
      />
      <CeMenuBuilderSimulations careers={classroomCeSimulations} />
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </div>
  );
};
