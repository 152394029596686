import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Column,
  DataTable,
  ColumnOptions,
  ColumnType,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";
import {
  Link,
  NotificationType,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import IntegrationDate from "./integration-date";
import IntegrationDateWithBadge from "./integration-date-with-badge";
import "./integration-table.scss";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import {
  RemoveIntegrationModal,
  SyncIntegrationModal,
} from "../../../../components/modals";
import container from "../../../../container";

const Option = ({ data, icon, text, helpText }) => (
  <DropdownItem value={{ action: text, data }}>
    <div className="dropdown-item">
      <FontAwesomeIcon icon={icon} />
      <div className="option-label">{text}</div>
      {!!helpText && (
        <TextTooltip text={helpText} position="top-end">
          <FontAwesomeIcon icon="fa-regular fa-circle-info" />
        </TextTooltip>
      )}
    </div>
  </DropdownItem>
);

const IntegrationTable = ({
  connectionList,
  launchNotification,
  isLoading,
  refresh,
}) => {
  const { currentUser } = useStoreState((store) => store.account);
  const { id: userId } = currentUser;
  const { integrationService } = container;

  const icons = useStoreState((state) => state.providerIcon.icons);
  const loadIcons = useStoreActions(
    (actions) => actions.providerIcon.loadIcons
  );
  const [connectionsWithIcons, setConnectionsWithIcons] = useState([]);
  const [syncModalData, setSyncModalData] = useState();
  const [removeModalData, setRemoveModalData] = useState();

  const responsiveQueryForTable = {
    "small-panel": {
      maxWidth: 430,
    },
    "large-panel": {
      minWidth: 430,
    },
  };

  const [responsiveStylesForTable, containerRef] = useContainerQuery(
    responsiveQueryForTable
  );

  useEffect(() => {
    loadIcons(connectionList.map((c) => c.providerId));

    setConnectionsWithIcons(
      connectionList.map((c) => ({
        ...c,
        icon: icons[c.providerId],
      }))
    );
  }, [connectionList, icons]);

  const onOptionSelected = ({ action, data }) => {
    switch (action) {
      case "remove":
        setRemoveModalData(data);
        break;
      case "sync":
        setSyncModalData(data);
        break;
      default:
        console.error("Invalid action selected:", action);
        break;
    }
  };

  const handleCloseModal = () => {
    setSyncModalData();
    setRemoveModalData();
  };

  const syncIntegration = async (data) => {
    try {
      await integrationService.syncIntegration(data.id, userId);
      await refresh(data.lastRunDate, data.id);
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error occurred and your ${
          data.providerName || ""
        } integration did not sync properly. Please try again. ${(
          <a onClick={() => setShowSyncErrorsModal(true)}>View Errors</a>
        )}`,
        icon: ["fa-solid", "triangle-exclamation"],
      });
    }
    handleCloseModal();
  };

  const onRemoveIntegration = async (data) => {
    try {
      await integrationService.removeIntegration(data.id);
      launchNotification({
        type: NotificationType.success,
        message: `Your ${
          data.providerName || ""
        } integration has been removed.`,
        icon: ["fa-solid", "circle-check"],
      });

      refresh();
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error ocurred during integration removal. Please try again.`,
        icon: ["fa-solid", "triangle-exclamation"],
      });
    }
    handleCloseModal();
  };

  const columns = [
    new Column(
      "providerName",
      "Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, rowData) => (
          <div className="name-block">
            <div className="image-block">
              <img
                className="provider-image"
                src={icons[rowData.providerId]}
                alt={value}
              />
            </div>
            <div className="link-block">
              <Link
                routerComponent={NavLink}
                underline
                to={`/app/integrations/${rowData.id}/sync-history`}
              >
                View Event Log
              </Link>
            </div>
          </div>
        ),
      })
    ),
    new Column(
      "createdDate",
      "Integrated",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => <IntegrationDate date={value} />,
      })
    ),
    new Column(
      "lastRunDate",
      "Last Sync",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, rowData) =>
          value ? <IntegrationDateWithBadge connection={rowData} /> : "Pending",
      })
    ),
    new Column(
      "id",
      " ",
      ColumnType.CUSTOM,
      new ColumnOptions({
        customFunction: (id, rowData) => {
          return (
            <Dropdown
              className={clsx("integration-menu", "icon-only")}
              selected={{}}
              onOptionSelected={onOptionSelected}
              disabled={isLoading}
            >
              <DropdownButton
                iconOnly
                icon={["fa-regular", "ellipsis-v"]}
                className="borderless"
              />
              <DropdownItems>
                <Option
                  data={rowData}
                  icon={["fa-regular", "times"]}
                  text="remove"
                />
                <Option
                  data={rowData}
                  icon={["fa-regular", "rotate"]}
                  text="sync"
                  helpText="Sync will happen automatically every 24 hours. You can also manually sync your classroom data now."
                />
              </DropdownItems>
            </Dropdown>
          );
        },
      })
    ),
  ];

  return (
    <div className="integration-table-container" ref={containerRef}>
      <DataTable
        className={clsx("connections-table", responsiveStylesForTable)}
        columns={columns}
        data={connectionsWithIcons}
        keyFields={["id"]}
        defaultSortPropName="createdDate"
        defaultSortDirection={SortDirection.ASCENDING}
        responsiveStyles={responsiveStylesForTable}
        rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
      />
      {!!syncModalData && (
        <SyncIntegrationModal
          integration={syncModalData}
          open={!!syncModalData}
          onCancel={handleCloseModal}
          onSyncIntegration={syncIntegration}
        />
      )}
      {!!removeModalData && (
        <RemoveIntegrationModal
          integration={removeModalData}
          open={!!removeModalData}
          onCancel={handleCloseModal}
          onRemoveIntegration={onRemoveIntegration}
        />
      )}
    </div>
  );
};

IntegrationTable.propTypes = {
  connectionList: PropTypes.array.isRequired,
  launchNotification: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default IntegrationTable;
