/**
 * Base class for all Snowplow analytics events.
 * Provides a standardized structure for event tracking with a schema
 * and data payload. All specific event types should extend this class.
 *
 * @abstract
 */
class Event {
  /**
   * Creates a new event with the specified Snowplow schema.
   * @param {string} schema - The Iglu schema URI that defines the event structure
   */
  constructor(schema) {
    this.schema = schema;
    this.data = {};
  }
}

/**
 * Tracks user interactions with dashboard date filtering.
 */
export class FilterDashboard extends Event {
  /**
   * Creates a new filter dashboard event with specified date range.
   * @param {string} startDate - The start date of the filter.
   * @param {string} endDate - The end date of the filter.
   */
  constructor(startDate, endDate) {
    super("iglu:com.transfrvr/filter_dashboard/jsonschema/1-0-0");
    this.data.start_date = startDate;
    this.data.end_date = endDate;
  }
}

/**
 * Tracks user logout events with the deauthentication method and if user was impersonator.
 */
export class DeauthenticateUser extends Event {
  constructor(deauthenticationMethod) {
    super("iglu:com.transfrvr/deauthenticate_user/jsonschema/3-0-0");
    this.data = {
      deauthentication_method: deauthenticationMethod,
    };
  }
}
