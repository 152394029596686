/* eslint-disable unicorn/no-null */
import React, { useEffect } from "react";

import {
  SlashCircleIcon,
  Tile,
  Loader,
} from "@transfr-inc/dashboard-components";

import { ShortcutAction } from "../../../../../components/shortcuts";
import "./mission-control-tiles.scss";
import {
  mapHeadsetStatus,
  mapUserStatus,
  generateTileProps,
} from "../../utils/mission-control.utils";

export const MissionControlTiles = ({
  missionControlDataArray,
  setSpotlightTile,
  spotlightTile,
  showZero,
  loading,
  imageData,
  showLiveFeed,
}) => {
  const zeroState = (
    <>
      {loading ? (
        <Loader overlay></Loader>
      ) : (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          {
            "No users to display, please wait until there are live users in the classroom"
          }
        </ShortcutAction>
      )}
    </>
  );

  useEffect(() => {
    if (showZero && spotlightTile) {
      setSpotlightTile();
    }
  }, [showZero]);

  const handleTileSelect = (tile) => {
    setSpotlightTile(tile);
  };

  return (
    <div className="mission-control-main-container">
      {showZero ? (
        zeroState
      ) : (
        <div className="tile-container">
          {missionControlDataArray.map((headsetInfo, i) => {
            const userInfo = headsetInfo.userInfo;
            const simStatus = mapHeadsetStatus(headsetInfo);
            const userStatus = mapUserStatus(headsetInfo);
            const showIdle = headsetInfo.isIdle && headsetInfo.isActive;
            const showInactive = !headsetInfo.isActive;
            const studentResults = headsetInfo?.studentResults;
            const tileImage = showLiveFeed ? imageData : null;

            const tileProps = generateTileProps(
              userInfo,
              simStatus,
              userStatus,
              showIdle,
              studentResults,
              headsetInfo,
              tileImage,
              handleTileSelect,
              showInactive
            );

            if (spotlightTile && spotlightTile.id === tileProps.id) {
              setSpotlightTile(tileProps);
              tileProps.isSpotlightTile = true;
              return;
            }

            return (
              <div key={i + headsetInfo.dateTime}>
                <Tile tileProps={tileProps} {...tileProps} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
