import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Loader,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import {
  CheckboxStates,
  TriCheckBox,
  Button,
} from "@transfr-inc/dashboard-components/forms";

import { useOrderer, OrderMove } from "@transfr-inc/dashboard-components/hooks";

import { ListItemEdition, Movement } from "../../components";

import "./edit-careers.modal.scss";

export const EditCareersModal = ({
  open,
  onClose,
  onApplyChanges,
  async,
  simulations,
  className,
}) => {
  const { CHECKED, EMPTY, INDETERMINATE } = CheckboxStates;
  const [selectAllItems, setSelectAllItems] = useState(true);
  const [loading, setLoading] = useState();
  const [animationParent] = useAutoAnimate();

  const [oldSimulations, setOldSimulations] = useState();

  const {
    items: simulationItems = [],
    updateItems: updateSimulations,
    move,
    isFirstItem,
    isLastItem,
  } = useOrderer({
    data: simulations,
    idProp: "id",
    orderProp: "orderNumber",
  });

  const simulationCount = useMemo(
    () => simulationItems?.filter((c) => c.isVisible).length,
    [simulationItems]
  );

  const checkboxValue = useMemo(() => {
    const selected = simulationItems?.filter((c) => c.isVisible).length;
    if (selected === simulationItems?.length) {
      return CHECKED;
    } else {
      return selected === 0 ? EMPTY : INDETERMINATE;
    }
  }, [simulationItems]);

  const onSelectAllItems = () => {
    const selectAllItemsValue = !selectAllItems;
    updateAllItems(selectAllItemsValue);
  };

  const onApply = async () => {
    if (async) {
      setLoading(true);
      await onApplyChanges?.(simulationItems, oldSimulations);
      setLoading();
    } else {
      onApplyChanges?.(simulationItems, oldSimulations);
    }
  };

  const onSimulationSelected = (index) => {
    updateSimulations(
      simulationItems.map((simulation, i) => {
        if (i === index) {
          simulation.isVisible = !simulation.isVisible;
        }
        return simulation;
      })
    );
  };

  const updateAllItems = (isAllSelected) => {
    updateSimulations(
      simulationItems.map((simulation) => ({
        ...simulation,
        isVisible: isAllSelected,
      }))
    );
    setSelectAllItems(isAllSelected);
  };

  const onChangeOrder = (movement, index) => {
    const orderMove =
      movement === Movement.Up ? OrderMove.Backward : OrderMove.Forward;
    move(orderMove, index);
  };

  useEffect(() => {
    if (open) {
      updateSimulations(simulations?.map((c, i) => ({ ...c, orderNumber: i })));
      let oldSims = simulations?.filter((c) => c.isVisible);
      if (oldSims.length === 0) {
        setOldSimulations(simulations);
      } else {
        setOldSimulations(oldSims);
      }
    }
  }, [open]);

  return (
    <Modal
      modalClassName={clsx("edit-careers-modal", className)}
      open={open}
      onClose={onClose}
      preventClose={loading}
    >
      {loading && <Loader overlay />}
      <ModalHeader icon={["fa-regular", "pen-to-square"]}>
        <div className="modal-title">
          <h2>Careers</h2>
          <Badge value={simulationCount} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <span>Check the careers that you would like included.</span>
          <br />
          <span>Unchecked careers can be recovered later.</span>
          <br />
          <RequiredItem
            className="required-container"
            text="At least 1 career must be selected"
          />
        </div>
        <div className="actions-container">
          <TriCheckBox
            labelPresent
            value={checkboxValue}
            onChange={onSelectAllItems}
          ></TriCheckBox>
        </div>
        <div className="category-list" ref={animationParent}>
          {simulationItems?.map((simulation, index) => {
            let occupationName;
            if (simulation?.occupationName) {
              occupationName = ` (${simulation?.occupationName.trim()})`;
            }
            return (
              <ListItemEdition
                key={`${simulation.id}`}
                isChecked={simulation?.isVisible}
                onChecked={() => onSimulationSelected(index)}
                isDownEnabled={!isLastItem(simulation)}
                isUpEnabled={!isFirstItem(simulation)}
                onChangeOrder={(movement) => onChangeOrder(movement, index)}
                label={simulation?.name + occupationName ?? ""}
              />
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary disabled={simulationCount === 0} onClick={onApply}>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
