import {
  newTracker,
  trackPageView,
  enableActivityTracking,
  trackSelfDescribingEvent,
  addGlobalContexts,
} from "@snowplow/browser-tracker";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking,
} from "@snowplow/browser-plugin-button-click-tracking";
import { Organization } from "../models/tracking/entities";
import { EVENT_TYPES } from "../models/tracking/event-types";
import { DEFAULT_TRACKER_CONFIG } from "../config/tracking-config";

/**
 * TrackingService is a utility class that integrates Snowplow tracking into the application.
 * It manages tracker initialization, global contexts, and event tracking for custom use cases.
 */
class TrackingService {
  #organizationCode = "";
  #initialized = false;

  /**
   *
   * @param {string} appId - The application identifier to associate with the events being tracked.
   * @param {boolean} enabled - Indicates whether the tracker is enabled. If `false`, no events will be sent.   */
  constructor(appId, enabled = false) {
    this.appId = appId;
    this.enabled = enabled;
  }

  /**
   * Sets the organization code, which is used to generate tracking contexts.
   * @param {string} organizationCode - The unique identifier for the organization.
   */
  setOrganizationCode(organizationCode) {
    this.#organizationCode = organizationCode;
  }

  /**
   * Generates global contexts for events.
   * For specific event types (e.g., "pv" for page view or "link_click"), no additional contexts are added.
   * For all other events, an organization context is added.
   * @param {object} args - Event arguments including event type.
   * @returns {Array} - An array of contexts to attach to the event.
   */
  #contextGenerator = (args) => {
    const excludedTypes = [EVENT_TYPES.PAGE_VIEW, EVENT_TYPES.LINK_CLICK];
    if (excludedTypes.includes(args.eventType)) {
      return [];
    }
    return new Organization(this.#organizationCode).toContext();
  };

  /**
   * Initializes the Snowplow tracker with the specified trackerId, collector URL and default settings.
   * It sets up plugins, global contexts, activity tracking, and tracks an initial page view.
   * @param {string} trackerId - The id of the Snowplow tracker.
   * @param {string} collectorUrl - The URL of the Snowplow collector.
   * @param {Object} config - Optional configuration parameters
   */
  initializeTracker(
    trackerId,
    collectorUrl,
    {
      cookieSameSite,
      performanceTiming,
      minimumVisitLength,
      heartbeatDelay,
    } = DEFAULT_TRACKER_CONFIG
  ) {
    if (!this.enabled) {
      console.info("Snowplow tracker not enabled");
      return;
    }

    if (!collectorUrl) {
      console.error("Collector URL is required");
      return;
    }

    try {
      newTracker(trackerId, collectorUrl, {
        appId: this.appId,
        cookieSameSite,
        contexts: {
          performanceTiming,
        },
        plugins: [ButtonClickTrackingPlugin(), LinkClickTrackingPlugin()],
      });

      addGlobalContexts([this.#contextGenerator]);

      enableActivityTracking({
        minimumVisitLength,
        heartbeatDelay,
      });

      enableButtonClickTracking();
      enableLinkClickTracking({ pseudoClicks: true });

      trackPageView();
      this.#initialized = true;
    } catch (error) {
      console.error("Failed to initialize Snowplow tracker:", error);
    }
  }

  /**
   * Tracks a custom event using a self-describing event schema.
   * Allows for flexible tracking of domain-specific or custom events.
   * @param {object} event - The event payload, which includes the schema and data.
   * @param {Array} [contexts=[]] - Optional additional contexts to attach to the event.
   */
  trackEvent(event, contexts = []) {
    if (!this.enabled || !this.#initialized) {
      return;
    }

    try {
      trackSelfDescribingEvent({
        event,
        context: contexts,
      });
    } catch (error) {
      console.error("Failed to track event:", error);
    }
  }
}

export default TrackingService;
